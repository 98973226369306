import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { searchActions } from '@actions';
import { mediaQuery } from '@components';
import {
  useClickOutside,
  useLocalStorage,
  useMediaQuery,
  usePromise,
} from '@hooks';
import { ResultModel } from '@models';
import { Button } from 'antd';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { Search, XCircle } from 'react-feather';

import ResultWindow from './result-window';
import styles from './search-box.module.scss';

interface Props {
  showOverlay: (state: boolean) => void;
  autoFocus: boolean;
  onItemSelect?: () => void;
}

const SearchBox: FC<Props> = ({
  showOverlay,
  autoFocus,
  onItemSelect = () => {},
}: Props) => {
  const router = useRouter();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [activeResult, setActiveResult] = useState(false);
  const [fetchingSearch, setFetchingSearch] = useState(false);
  const [clearButtonState, setClearButtonState] = useState(false);
  const [recentSearchesResult, setRecentSearchesResult] = useState<
    ResultModel[]
  >([]);
  const [userSearchResult, setUserSearchResult] = useState<ResultModel[]>([]);
  const ResultWindowRef = useRef(null);
  const baseContainerRef = useRef(null);
  const isDesktop = useMediaQuery(mediaQuery.desktop);

  useClickOutside(baseContainerRef, () => {
    setActiveResult(false);
    showOverlay(false);
  });

  const promise = usePromise();

  const {
    localItems: recentSearchesItems,
    setItem: setRecentSearch,
    removeItemByIndex: removeRecentByIndex,
    removeAllItems: removeAllRecentSearches,
  } = useLocalStorage('u_recent_searches', 10);

  useEffect(() => {
    if (autoFocus) {
      searchInputRef.current?.focus();
      onFocus();
    }

    addRecentSearchesToResult();
  }, [recentSearchesItems]);

  useEffect(() => {
    const handleDesktopRouteChange = () => {
      if (isDesktop) {
        handleItemSelect();
      }
    };

    const handleMobileRouteChange = () => {
      if (!isDesktop) {
        handleItemSelect();
      }
    };

    router.events.on('routeChangeStart', handleDesktopRouteChange);
    router.events.on('routeChangeComplete', handleMobileRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleDesktopRouteChange);
      router.events.off('routeChangeComplete', handleMobileRouteChange);
    };
  }, []);

  useEffect(() => {
    searchInputRef.current?.addEventListener('focus', onFocus);

    return () => {
      searchInputRef?.current?.removeEventListener('focus', onFocus);
    };
  }, [recentSearchesItems]);

  const addRecentSearchesToResult = () => {
    const recentSearchesResult: ResultModel[] = recentSearchesItems.map(
      (item) => {
        return {
          id: recentSearchesItems.indexOf(item),
          type: 'recent',
          title: item,
        } as ResultModel;
      }
    );

    setRecentSearchesResult(recentSearchesResult);
  };

  const onRemoveAllRecentSearches = () => {
    removeAllRecentSearches();
    searchInputRef.current?.focus();
    onFocus();
  };

  const onFocus = () => {
    addRecentSearchesToResult();
    showOverlay(true);

    if (searchInputRef.current)
      if (
        recentSearchesItems.length > 0 &&
        searchInputRef.current?.value.length === 0
      ) {
        setActiveResult(true);
      } else if (searchInputRef.current?.value.length > 1) {
        handleRecentSearchResult(searchInputRef.current?.value);
        setActiveResult(true);
      }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      gotoSearchResultPage();
    }
  };

  const gotoSearchResultPage = () => {
    const term: string = searchInputRef.current?.value ?? '';

    if (term.trim().length > 0) {
      router.push('/search?q=' + term.trim());

      if (isDesktop) {
        setTimeout(() => {
          handleItemSelect();
        }, 1000);
      }

      return;
    }

    // Fade out search when the search bar is empty and user clicked on search button
    setActiveResult(false);
    showOverlay(false);
    return;
  };

  const handleSearchInputChange = (e) => {
    const term: string = e.target.value?.trim();

    setClearButtonState(term.length > 0);

    if (term.length < 3) {
      setUserSearchResult([]);
      addRecentSearchesToResult();
      return;
    }

    handleRecentSearchResult(term);

    if (term.length > 2) {
      startSearchWithDebounce(term);
    }
  };

  const handleRecentSearchResult = (term: string) => {
    const recentSearchesResult: ResultModel[] = recentSearchesItems
      .filter((item) => item.indexOf(term) > -1)
      .map((item) => {
        return {
          id: recentSearchesItems.indexOf(item),
          type: 'recent',
          title: item,
        } as ResultModel;
      });

    setRecentSearchesResult(recentSearchesResult);

    if (recentSearchesResult.length > 0 && activeResult === false)
      setActiveResult(true);
  };

  const handleClearSearchInput = () => {
    if (fetchingSearch) return;
    if (searchInputRef.current) searchInputRef.current.value = '';
    setClearButtonState(false);
  };

  const startSearch = (term: string) => {
    setFetchingSearch(true);
    setUserSearchResult([]);

    promise(searchActions.catalogSearch({ q: term })).then((result: any) => {
      const list = result?.data.map((r: any, idx) => {
        return {
          id: idx,
          type: 'search',
          title: r.title,
          item_type_title: r.item_type_title,
          category_slug: r.category_slug,
        } as ResultModel;
      });

      setUserSearchResult(list);
      setFetchingSearch(false);

      if (result?.data.length > 0 && activeResult === false)
        setActiveResult(true);
    });
  };

  const handleItemSelect = () => {
    setUserSearchResult([]);
    showOverlay(false);

    if (searchInputRef.current)
      if (searchInputRef.current.value.trim().length > 0) {
        setRecentSearch(searchInputRef.current.value.trim());
        searchInputRef.current.value = '';
        searchInputRef.current.blur();
      }

    if (onItemSelect) {
      onItemSelect();
    }

    setClearButtonState(false);
    setActiveResult(false);
  };

  const startSearchWithDebounce = useCallback(debounce(startSearch, 800), []);

  return (
    <div
      ref={baseContainerRef}
      className={`${styles.Container} 
        ${
          activeResult &&
          (recentSearchesResult.length > 0 || userSearchResult.length > 0)
            ? styles.active
            : ''
        }
          `}
    >
      <input
        type="search"
        ref={searchInputRef}
        onChange={handleSearchInputChange}
        onKeyPress={handleSearchKeyPress}
        placeholder="Search products & brands..."
      />
      {clearButtonState && (
        <div className={styles.ClearButton} onClick={handleClearSearchInput}>
          <XCircle width="100%" height="20px" color={'#000'} />
        </div>
      )}

      <Button
        onClick={gotoSearchResultPage}
        type="primary"
        className={styles.SearchButton}
        loading={fetchingSearch}
        icon={<Search width={16} height={16} color={'#fff'} />}
      />
      <div ref={ResultWindowRef}>
        <ResultWindow
          // onItemSelect={handleItemSelect}
          onItemSelect={gotoSearchResultPage}
          activeResult={activeResult}
          recentSearchesResult={recentSearchesResult}
          userSearchResult={userSearchResult}
          removeSearch={removeRecentByIndex}
          removeAll={onRemoveAllRecentSearches}
        />
      </div>
    </div>
  );
};

export default SearchBox;
