import { FC } from 'react';

import { convertToSlug } from '@helpers';
import { Button } from 'antd';
import truncate from 'lodash/truncate';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Clock, Search, X } from 'react-feather';

import styles from './search-box.module.scss';

export interface ResultModel {
  id: number;
  type: 'recent' | 'search';
  title: string;
  item_type_title?: string;
  category_slug?: string;
}
interface Props {
  activeResult: boolean;
  recentSearchesResult: ResultModel[];
  userSearchResult: ResultModel[];
  removeSearch: (index: number) => void;
  removeAll: () => void;
  onItemSelect: () => void;
}

const ResultWindow: FC<Props> = ({
  activeResult,
  recentSearchesResult,
  userSearchResult,
  removeSearch,
  removeAll,
  onItemSelect,
}: Props) => {
  const router = useRouter();

  const onItemClick = (e, url: string) => {
    if (onItemSelect) onItemSelect();
    e.preventDefault();
    router.push(url);
  };

  const renderRecentSearches = () => {
    return recentSearchesResult.map((item) => {
      return (
        <li key={item.title}>
          <div>
            <Clock color="#757575" width={16} height={16} />
            <p>
              <Link href={`/search?q=${convertToSlug(item.title)}`} prefetch={false}>
                <a
                  onClick={(e) =>
                    onItemClick(e, `/search?q=${convertToSlug(item.title)}`)
                  }
                >
                  {truncate(item.title, {
                    length: 45,
                    separator: ' ',
                    omission: ' ...',
                  })}
                </a>
              </Link>
            </p>
          </div>
          <X
            color="#757575"
            width={16}
            height={16}
            onClick={() => removeSearch(item.id)}
          />
        </li>
      );
    });
  };

  const renderSearchResult = () => {
    const MAX_RESULT = 10;

    const userSearches: ResultModel[] = userSearchResult.slice(
      0,
      MAX_RESULT - recentSearchesResult.length
    );

    return userSearches.map((item) => {
      let url = `/search?q=${convertToSlug(item.title)}`;

      if (item.item_type_title && item.item_type_title.length > 0)
        url = `/category/${item.category_slug}/products`;

      return (
        <li key={item.title}>
          <div>
            <Search color="#757575" width={16} height={16} />
            <p>
              <Link href={url} prefetch={false}>
                <a onClick={(e) => onItemClick(e, url)}>
                  {truncate(item.title, {
                    length: 45,
                    separator: ' ',
                    omission: ' ...',
                  })}
                </a>
              </Link>
            </p>
          </div>
          <p>{item.item_type_title}</p>
        </li>
      );
    });
  };

  return (
    <div
      className={`${styles.ResultContainer} ${
        activeResult ? styles.ActiveResultContainer : ''
      }`}
    >
      <div className={styles.ResultItems}>
        <ul>
          {recentSearchesResult.length > 0 && (
            <header>
              <p>Recent</p>
              <Button type="text" onClick={removeAll}>
                Clear All
              </Button>
            </header>
          )}
          {renderRecentSearches()}
          {renderSearchResult()}
        </ul>
      </div>
    </div>
  );
};

export default ResultWindow;
